import axios from "axios";

const global = require("../config/config");

async function InsertDados(
  Marcas,
  Modelos,
  Combustivel,
  Segmento,
  Transmissao,
  Extras,
  AnoInicial,
  AnoFinal,
  PrecoInicial,
  PrecoFinal,
  KmInicial,
  KmFinal,
  PotenciaInicial,
  IdViatura,
  infoJson
) {
  try {
    const accessToken = localStorage.getItem("acess-token");
    var options = {
      method: "POST",
      url: `${global.ApiLink}/v1/remarketing/dados/InsertHistoricoPesquisa/`,

      headers: {
        Authorization: "Bearer " + accessToken.replace(/^"|"$/g, ""),
        "Content-Type": "application/json",
      },
      data: {
        brand_id: `${Marcas.toString()}`,
        model_id: `${Modelos.toString()}`,
        type_id: `${Segmento.toString()}`,
        tramission_id: `${Transmissao.toString()
          .replace("1", "33, 34, 35, 40, 42")
          .replace("2", "32, 36, 37, 38, 39, 41, 43, 44")}`,
        fuel_id: `${Combustivel.toString()}`,
        extras: `${Extras.toString()}`,
        price_max: PrecoFinal,
        price_min: PrecoInicial,
        km_min: KmInicial,
        km_max: KmFinal,
        age_min: AnoInicial,
        age_max: AnoFinal,
        potency_min: PotenciaInicial,
        vehicle_id: IdViatura,
      },
    };

    await axios
      .request(options)
      .then(function (response) {})
      .catch(function (error) {
        console.error(error);
      });
  } catch (error) {
    console.error("error", error);
  }
}

async function InsertUpload(URL, infoJson) {
  try {
    const accessToken = localStorage.getItem("acess-token");
    var options = {
      method: "POST",
      url: `${global.ApiLink}/v1/remarketing/dados/InsertUploads/`,

      headers: {
        Authorization: "Bearer " + accessToken.replace(/^"|"$/g, ""),
        "Content-Type": "application/json",
      },
      data: {
        url: URL,
      },
    };

    await axios
      .request(options)
      .then(function (response) {})
      .catch(function (error) {
        console.error(error);
      });
  } catch (error) {
    console.error("error", error);
  }
}

export { InsertDados, InsertUpload };
