/**
 * Esta é uma função tranforma 1 digito para dois
 *  @example
 * pad(5);
 * // returns 05
 *
 * @param   {Number} val   number
 * @returns {Number} number
 *
 */

function pad(val) {
  return !/\D/.test(val) ? (val < 10 ? "0" + val : val) : "";
}

/**
 * Esta é uma funçãocria a morada
 *  @example
 * tratarMorada("rua", "4730", "040", "Braga");
 * // returns  "rua, 4730-040 Braga"
 *
 * @param   {String} rua   rua
 * @param   {String} cp4   codigo postao 4
 * @param   {String} cp3   codigo postao 3
 * @param   {String} localidade   Localidade
 * @returns {String} morada
 *
 */

function tratarMorada(rua, cp4, cp3, localidade) {
  // Formatar a morada

  let morada = "";

  if (rua) {
    morada = rua;
    if (cp4) {
      morada = morada + ", " + cp4;
      if (cp3) {
        morada = morada + "-" + cp3;
      }
      if (localidade) {
        morada = morada + " " + localidade;
      }
    }
  }
  return morada;
}
function tratarLink(linker, nome) {
  //tratar os links que seram criados

  let link;
  let linkHandler;

  switch (nome) {
    case "Messenger":
      linkHandler = linker.replaceAll("https://www.facebook.com/", "");
      linkHandler = linkHandler.replaceAll("http://www.facebook.com/", "");
      linkHandler = linkHandler.replaceAll("//", "/");
      link = "https://www.messenger.com/t/" + linkHandler;
      break;

    case "Whatsapp":
      linkHandler = linker.replaceAll(" ", "");
      linkHandler = linkHandler.replaceAll("+351", "");
      linkHandler.length === 9
        ? (link = "https://api.whatsapp.com/send?phone=351" + linkHandler)
        : (link = "");

      break;
    case "Telefone":
      linkHandler = linker.replaceAll(" ", "");
      linkHandler = linkHandler.replaceAll("+351", "");
      linkHandler.length === 9
        ? (link = "tel:+351" + linkHandler)
        : (link = "");

      break;
    case "Telemovel":
      linkHandler = linker.replaceAll(" ", "");
      linkHandler = linkHandler.replaceAll("+351", "");
      linkHandler.length === 9
        ? (link = "tel:+351" + linkHandler)
        : (link = "");

      break;
    case "SMS":
      linkHandler = linker.replaceAll(" ", "");
      linkHandler = linkHandler.replaceAll("+351", "");

      linkHandler.length === 9
        ? (link = "sms:+351" + linkHandler)
        : (link = "");

      break;
    case "Email":
      linkHandler = linker;
      link = "mailto:" + linkHandler;

      break;

    default:
      link = linker;
      break;
  }

  return link;
}
function tratarIframe(linker) {
  //fomatar o link do mapa

  let iframe = "";
  if (linker && linker.includes('src="')) {
    iframe = linker.split('src="').pop().split('"')[0];
  }

  return iframe;
}

/**
 * Esta é uma função que coloca preto  ou branco comforme
 * a cor dos btn
 *
 *
 * @param   {String} hexcolor   Hexa
 * @returns {String} white/black
 */

function textColor(hexcolor) {
  try {
    hexcolor = hexcolor.replaceAll("#", "");
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "black" : "white";
  } catch (error) {
    console.log("error", error);
    return "";
  }
}

/**
 * Esta é uma função trata o horaio que vem da api
 *
 *
 * @param   {object} horario   array
 * @returns {object} array de horario com nova informação
 */

function tratarhorario(horario) {
  try {
    const currentTime = new Date();
    let aberto = {
      boll: false,
      tipo: null,
      name: null,
    };
    const ArrayHorarios = horario[0].Desc.split(",");
    const proximoDiaAberto = horario
      .slice(1)
      .find((item) => item.IsOpen === true);

    if (proximoDiaAberto === undefined && horario[0].IsOpen === false) {
      if (!horario[0].IsOpen && horario[0].Appointment) {
        aberto = {
          boll: true,
          tipo: 2,
          name: "Por marcação",
        };
        return aberto;
      } else {
        aberto = {
          boll: true,
          tipo: 2,
          name: "Fechado",
        };
        return aberto;
      }
    }

    if (horario[0].IsOpen === false) {
      //verica que data é o dia de amanha
      let tomorrow = new Date();
      tomorrow.setDate(currentTime.getDate() + 1);
      const h = proximoDiaAberto.Desc.split(",");

      if (
        proximoDiaAberto.date ===
        `${tomorrow.getFullYear()}-${pad(tomorrow.getMonth() + 1)}-${pad(
          tomorrow.getDate()
        )}`
      ) {
        aberto = {
          boll: true,
          tipo: 3,
          name: `Abre Amanhã às ${h[0].split("-")[0]}h`,
        };
        return aberto;
      }

      aberto = {
        boll: true,
        tipo: 3,
        name: `Abre ${proximoDiaAberto.Day} às ${h[0].split("-")[0]}h`,
      };
      return aberto;
    }
    //horario do dia atual

    for (let index = 0; index < ArrayHorarios.length; index++) {
      const h = ArrayHorarios[index].split("-");

      //data de abertura
      const startTime = new Date();

      startTime.setHours(parseInt(h[0].split(":")[0]));
      startTime.setMinutes(parseInt(h[0].split(":")[1]));
      startTime.setSeconds(0);
      //data de fecho
      const endTime = new Date();
      endTime.setHours(parseInt(h[1].split(":")[0]));
      endTime.setMinutes(parseInt(h[1].split(":")[1]));
      endTime.setSeconds(0);

      //verifica se esta entre o horario de abertura

      if (aberto.boll === false) {
        //horario de abertura , quando o stand esta aberto
        if (
          currentTime.getTime() > startTime.getTime() &&
          currentTime.getTime() < endTime.getTime()
        ) {
          //uma hora antes de fechar diz que a que horas vai fechar
          const t30 = new Date(endTime.getTime());
          t30.setHours(t30.getHours() - 1);

          if (
            currentTime.getTime() > t30.getTime() &&
            currentTime.getTime() < endTime.getTime()
          ) {
            aberto = {
              boll: true,
              tipo: 3,
              name: `Fecha Brevemente às ${pad(endTime.getHours())}:${pad(
                endTime.getMinutes()
              )}h`,
            };
          } else {
            aberto = {
              boll: true,
              tipo: 1,
              name: "Aberto",
            };
          }
        } else {
          //uma hora antes de abrir

          if (currentTime.getTime() < startTime.getTime()) {
            aberto = {
              boll: true,
              tipo: 3,
              name: `Abre às ${h[0].split("-")[0]}h`,
            };
          } else {
            //verica que data e o dia de amanha
            let tomorrow = new Date();
            tomorrow.setDate(currentTime.getDate() + 1);
            const h = proximoDiaAberto.Desc.split(",");

            if (
              proximoDiaAberto.date ===
              `${tomorrow.getFullYear()}-${pad(tomorrow.getMonth() + 1)}-${pad(
                tomorrow.getDate()
              )}`
            ) {
              aberto = {
                boll: false,
                tipo: 3,
                name: `Abre Amanhã às ${h[0].split("-")[0]}h`,
              };
            }

            aberto = {
              boll: false,
              tipo: 3,
              name: `Abre ${proximoDiaAberto.Day} às ${h[0].split("-")[0]}h`,
            };
          }
        }
      }
    }

    return aberto;
  } catch (error) {
    console.log("error", error);
  }
}

const tratarIntermediacao = (json, link, pdf) => {
  if (pdf) {
    return pdf;
  } else {
    if (link) {
      return link;
    } else {
      if (json) {
        return json;
      } else {
        return "";
      }
    }
  }
};

export {
  tratarMorada,
  tratarLink,
  tratarIframe,
  textColor,
  tratarhorario,
  tratarIntermediacao,
  pad,
};
