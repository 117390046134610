import css from "./siteholder.module.css";
import { ApiLinkImagens } from "../../../config/config";

const logo = `${ApiLinkImagens}/logo`;

export default function Siteholder({ infoJson }) {
  return (
    <div
      className={`${css.box} ${
        infoJson.Layout.ThemeDark ? css.black : css.white
      }`}
    >
      <img src={logo} className={` ${css.img}`} alt={infoJson.Stand.Nome} />
    </div>
  );
}
