import { lazy, Suspense, useContext, useEffect, useState } from "react";
import Rotas from "./components/routes/Rotas";
import * as apiCarros from "./api/apiCarros";
import * as apiJson from "./api/apiJson";
import * as apiEmpresa from "./api/apiEmpresa";

import useStateWithLocalStorage from "./components/hooks/useStateWithLocalStorage ";
import useMediaQuery from "./components/hooks/useMediaQuery ";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "styled-components";
import ReactSEOMetaTags from "react-seo-meta-tags";
import { lightTheme, darkTheme } from "./config/theme";
import GlobalTheme from "./config/global";
import CookieConsentir from "./components/tools/cookies/CookieConsentir";

import AuthContext from "./components/hooks/AuthContext";
import WidgetProvider from "./components/hooks/WidgetProvider";
import Siteholder from "./components/tools/placeholder/Siteholder";

import { useLocation } from "react-router";
import CountdownTimer from "./components/tools/count-down/CountdownTimer";
import Token from "./components/tools/placeholder/Token";
import Fim from "./components/tools/placeholder/Fim";

const Snow = lazy(() => import("./components/tools/snowFall/Snow"));

const global = require("./config/config");
export default function App() {
  const locationLink = useLocation();
  const { state, dispatch } = useContext(AuthContext);
  const [infoJson, setInfoJson] = useState([]);
  const [metaTags, setMetaTags] = useState([]);

  const [selectedCarsComparar, setSelectedCarsComparar] =
    useStateWithLocalStorage("myValueInLocalStorage");
  const [, setToken] = useStateWithLocalStorage("acess-token");
  const [showModalComparison, setShowModalComparison] = useState(false);
  const [textoModal, setTextoModal] = useState(false);
  const [allinfoText] = useState([]);

  useEffect(() => {
    var paramsBusca = new URLSearchParams(locationLink.search);
    const token = paramsBusca.get("token");

    token && setToken(token);
  }, [locationLink.search, setToken]);

  useEffect(() => {
    const getJson = async () => {
      const info = await apiJson.infoJson();
      setInfoJson(info);
    };
    getJson();
  }, []);

  useEffect(() => {
    const getViaturas = async () => {
      const viaturas = await apiCarros.getListaViaturas(infoJson);

      dispatch({ type: "AddLista", list: viaturas });

      const Carros = viaturas.filter((carro) => {
        return (
          carro.Estado.Id !== 41 && global.carrosNormais.includes(carro.Tipo.Id)
        );
      });
      dispatch({ type: "AddCarros", list: Carros });

      const Comercias = viaturas.filter((carro) => {
        return (
          carro.Estado.Id !== 41 &&
          global.carrosComerciais.includes(carro.Tipo.Id)
        );
      });
      dispatch({ type: "AddComercias", list: Comercias });

      const Classicos = viaturas.filter((carro) => {
        return carro.Estado.Id === 41;
      });
      dispatch({ type: "AddClassicos", list: Classicos });

      const Autocaravanas = viaturas.filter((carro) => {
        return (
          carro.Estado.Id !== 41 &&
          global.carrosAutocaravanas.includes(carro.Tipo.Id)
        );
      });
      dispatch({ type: "AddAutocaravanas", list: Autocaravanas });

      const Tractor = viaturas.filter((carro) => {
        return carro.Estado.Id !== 41 && global.tractor.includes(carro.Tipo.Id);
      });
      dispatch({ type: "AddTractor", list: Tractor });
      const MobileHomes = viaturas.filter((carro) => {
        return (
          carro.Estado.Id !== 41 && global.mobileHomes.includes(carro.Tipo.Id)
        );
      });
      dispatch({ type: "AddMobileHomes", list: MobileHomes });
    };
    if (Object.keys(infoJson).length !== 0) getViaturas();
  }, [infoJson, dispatch]);

  useEffect(() => {
    const getEmpresas = async () => {
      const info = await apiEmpresa.getInfoEmpresa();
      dispatch({ type: "AddPrincipal", list: info });
    };

    getEmpresas();
  }, [dispatch]);

  useEffect(() => {
    const getBanner = async () => {
      const banners = await apiEmpresa.getBanner(
        infoJson.Banner.Desktop,
        infoJson
      );

      banners?.length !== 0 &&
        dispatch({
          type: "AddTimeout",
          list: banners[0]?.DataInativacao.split(/[\s/:]/),
        });
    };
    if (Object.keys(infoJson).length !== 0) getBanner();
  }, [infoJson, dispatch]);

  const isDesktop = useMediaQuery("(min-width: 992px)");

  const addToCompare = (item) => {
    let numLimit;
    !isDesktop ? (numLimit = 2) : (numLimit = 3);
    if (
      selectedCarsComparar.length >= 1 &&
      item.Viatura !== selectedCarsComparar[0].Viatura
    ) {
      setShowModalComparison(true);
      setTextoModal(
        " O viatura que tentaste adicionar não é compatível com os restantes viaturas no comparador."
      );
      return;
    }
    if (selectedCarsComparar.length >= numLimit) {
      setShowModalComparison(true);
      setTextoModal(
        " O limite de viaturas no comparador foi atingido. Por favor, remove uma viatura para poderes adicionar mais."
      );
    } else {
      setSelectedCarsComparar((selectedCarsComparar) => [
        ...selectedCarsComparar,
        item,
      ]);
    }
  };

  const removeFromCompare = (item) => {
    const filteredItems = selectedCarsComparar.filter(
      (product) => product.CodViatura !== item.CodViatura
    );
    setSelectedCarsComparar((selectedCarsComparar) => filteredItems);
  };
  const removeFromCompareAll = () => {
    setSelectedCarsComparar([]);
  };

  useEffect(() => {
    selectedCarsComparar.length > 0 &&
      (document.body.style.overflow = "visible");
  }, [selectedCarsComparar]);

  return (
    <>
      {Object.keys(infoJson).length !== 0 &&
        Object.keys(process.envx).length !== 0 && (
          <>
            <WidgetProvider infoJson={infoJson}>
              <ThemeProvider
                theme={infoJson.Layout.ThemeDark ? darkTheme : lightTheme}
              >
                <GlobalTheme infoJson={infoJson} />
                <HelmetProvider>
                  <ReactSEOMetaTags
                    render={(el) => (
                      <Helmet>
                        {/* Chrome, Firefox OS and Opera  */}
                        <meta
                          name="theme-color"
                          content={infoJson.Cores.FundoTransparentScroll}
                        />
                        {/* Windows Phone  */}
                        <meta
                          name="msapplication-navbutton-color"
                          content={infoJson.Cores.FundoTransparentScroll}
                        />
                        {/* iOS Safari  */}
                        <meta
                          name="apple-mobile-web-app-status-bar-style"
                          content={infoJson.Cores.FundoTransparentScroll}
                        />
                        {infoJson.Stand.PluginFacebok.existe && (
                          <meta
                            name="facebook-domain-verification"
                            content={
                              infoJson.Stand.PluginFacebok
                                .facebookDomainVerification
                            }
                          />
                        )}

                        {infoJson.Stand.Lead2team.existe && (
                          <script type="text/javascript">
                            {` 
              (function (w,d,s,o,f,js,fjs,mpd) { w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
              mpd = d.createElement('div');
              mpd.id = 'widget_'+o; d.body.appendChild(mpd); js = d.createElement(s), fjs = d.getElementById('widget_'+o); js.id = o; js.src = f; 
              js.async = 1; fjs.parentNode.insertBefore(js, fjs); }(window, document, 'script', 'lead2team', 'https://online.lead2team.com/widget/widget.js'));
              lead2team('init', { apiKey: '${infoJson.Stand.Lead2team.apiKey}' });
              lead2team('lead2team', { groups: '', locations: ''}); 
            `}
                          </script>
                        )}

                        {el}
                      </Helmet>
                    )}
                    website={metaTags.fullWebsiteData}
                    facebook={metaTags.facebookData}
                    twitter={metaTags.twitterData}
                    blogPost={metaTags.blogPostData}
                  />
                </HelmetProvider>
                {infoJson.Stand.Snowfall.existe && (
                  <Suspense fallback={<></>}>
                    <Snow infoJson={infoJson} />
                  </Suspense>
                )}

                {infoJson["acesso-granted"] === undefined ? (
                  <>
                    <Siteholder infoJson={infoJson} />
                  </>
                ) : !infoJson["acesso-granted"] ? (
                  <Token />
                ) : (
                  <>
                    {state.timeout.length > 0 ? (
                      <CountdownTimer
                        targetDate={new Date(
                          state.timeout[2],
                          state.timeout[1] - 1,
                          state.timeout[0],
                          state.timeout[3],
                          state.timeout[4],
                          state.timeout[5]
                        ).getTime()}
                      >
                        <Rotas
                          setMetaTags={setMetaTags}
                          infoJson={infoJson}
                          selectedItems={selectedCarsComparar}
                          addToCompare={addToCompare}
                          removeFromCompare={removeFromCompare}
                          allinfoText={allinfoText}
                          selectedCarsComparar={selectedCarsComparar}
                          removeFromCompareAll={removeFromCompareAll}
                          setTextoModal={setTextoModal}
                          textoModal={textoModal}
                          setShowModalComparison={setShowModalComparison}
                          showModalComparison={showModalComparison}
                        />
                      </CountdownTimer>
                    ) : (
                      <>
                        <Fim />
                      </>
                    )}
                  </>
                )}

                <CookieConsentir />
              </ThemeProvider>
            </WidgetProvider>
          </>
        )}
    </>
  );
}
