import { Select } from "react-functional-select";
import  { useState, useEffect, useCallback, useRef } from "react";
import { ReactComponent as Arrow } from "../../../../../assets/icons/pesquisa/select-seta.svg";
import { ReactComponent as Clear } from "../../../../../assets/icons/pesquisa/fechar-select.svg";
import { ButtonMuiltiSelect } from "../../../../helpers/tratarInfo";
import css from "../pesquisa.module.css";

export default function TransmissaoSelect({
  Array,
  onChange,
  UpdateSelectRef,
  ArraySelect,
  infoJson,
}) {
  const selectRef = useRef();
  const [option, setOption] = useState([]);
  const getOptionValue = useCallback((option) => option.CodTransmissao, []);
  const getOptionLabel = useCallback((option) => option.Transmissao, []);

  const onOptionChange = (option) => {
    const item = option.map((i) => {
      return i.CodTransmissao;
    });
    const selectedInt = item.map((i) => Number(i));
    onChange(selectedInt);
  };

  useEffect(() => {
    selectRef.current?.setValue(ArraySelect);
  }, [ArraySelect]);

  const renderMultiOptions = useCallback(
    ({ selected }) => (
      <div>
        {selected.length > 0 &&
          `${selected.length} ${
            selected.length === 1 ? "selecionado" : "selecionados"
          }`}
      </div>
    ),
    []
  );

  useEffect(() => {
    setOption(Array);
    UpdateSelectRef(selectRef);
  }, [Array, UpdateSelectRef]);
  const clear = (
    <span>
      <Clear fill={infoJson.Cores.SVG} width="10px" />
    </span>
  );
  const arrow = (
    <span>
      <Arrow fill={infoJson.Cores.SVG} className={css.arrow} />
    </span>
  );
  return (
    <Select
      ref={selectRef}
      isMulti
      isClearable
      isSearchable={false}
      options={option}
      initialValue={ArraySelect}
      placeholder="Selecionar"
      onOptionChange={onOptionChange}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      openMenuOnClick={true}
      blurInputOnSelect={false}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      renderMultiOptions={renderMultiOptions}
      noOptionsMsg="Não existe"
      clearIcon={clear}
      caretIcon={arrow}
      themeConfig={ButtonMuiltiSelect(infoJson)}
    />
  );
}
