import  { useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PesquisaAvancada from "./PesquisaAvançada/PesquisaAvancada";
import css from "./pesquisa.module.css";
import PesquisaRapida from "./PesquisaRapida/PesquisaRapida";
import { ReactComponent as PesquisaAnvanca } from "../../../../assets/icons/pesquisa/pesquisa_ava.svg";
import Spacer from "../Spacer";
import { useLocation } from "react-router";
import { useEffect } from "react";
import AuthContext from "../../../hooks/AuthContext";

export default function PesquisaBox({ setFilteredCarros, infoJson, home }) {
  const { state } = useContext(AuthContext);
  const location = useLocation();
  const [avancada, setAvancada] = useState(
    infoJson.Pesquisa.avancada.principal
  );
  useEffect(() => {
    location.state && location.state.pesquisaAvancada && setAvancada(true);
  }, [location]);

  const clasSwift = (value) => {
    switch (value) {
      case 1:
        return `${css.colPesquisaPadding}`;
      case 2:
        return `${css.colPesquisaFloat}`;
      case 3:
        return `${css.colPesquisaFacilInline}`;
      case 4:
        return `${css.colPesquisaRapidoBox}`;
      case 5:
        return `${css.colPesquisaRapidoInline}`;
      case 6:
        return `${css.colPesquisaFloatBottom}`;

      default:
        return css.colPesquisaPadding;
    }
  };

  return (
    <>
      {!avancada && infoJson.Pesquisa.rapida.tipo === 2 && home ? (
        <div style={{ display: infoJson.Pesquisa.existe ? " block" : "none" }}>
          <Container>
            <div className={`${css.sectionFloat}`}>
              <div
                className={`${css.tituloReduzido} ${
                  home ? "titulo-Reduzido" : "titulo-Reduzido-interior"
                }  ps-4 mb-2 `}
              >
                Que Viatura Procura?
              </div>
              <div
                className={` ${css.colPesquisaMenu} colPesquisaMenu ${clasSwift(
                  infoJson.Pesquisa.rapida.tipo
                )} `}
              >
                <PesquisaRapida
                  setFilteredCarros={setFilteredCarros}
                  infoJson={infoJson}
                  avancada={avancada}
                />
              </div>

              {!avancada && state.allCarros.length !== 0 && (
                <div
                  onClick={() => {
                    setAvancada(true);
                  }}
                  className={`${css.btn_avancada}  btn_avancada`}
                >
                  <PesquisaAnvanca />
                  Pesquisa Detalhada
                </div>
              )}

              <PesquisaAvancada
                setFilteredCarros={setFilteredCarros}
                infoJson={infoJson}
                setAvancada={setAvancada}
                avancada={avancada}
              />
            </div>
          </Container>
        </div>
      ) : (
        <section
          style={{ display: infoJson.Pesquisa.existe ? " block" : "none" }}
          className={`${css.section}`}
        >
          <Container>
            <Row>
              <Col xs={12}>
                {infoJson.Pesquisa.rapida.tipo === 1 ? (
                  <div className={`${css.titulo} titulo-Pesquisa `}>
                    {avancada ? <>Que Viatura Procura</> : <>Pesquisa Rápida</>}
                  </div>
                ) : (
                  <div
                    className={`${css.tituloReduzido} ${
                      home ? "titulo-Reduzido" : "titulo-Reduzido-interior"
                    } text-center mb-2`}
                  >
                    Que Viatura Procura?
                  </div>
                )}
              </Col>

              <Col
                xs={12}
                xl={{ span: 10, offset: 1 }}
                xxl={{ span: 8, offset: 2 }}
                className={` ${css.colPesquisaMenu} colPesquisaMenu ${
                  !avancada
                    ? clasSwift(infoJson.Pesquisa.rapida.tipo)
                    : css.colPesquisaPaddingAvan
                } `}
              >
                <PesquisaRapida
                  setFilteredCarros={setFilteredCarros}
                  infoJson={infoJson}
                  avancada={avancada}
                />

                <PesquisaAvancada
                  setFilteredCarros={setFilteredCarros}
                  infoJson={infoJson}
                  setAvancada={setAvancada}
                  avancada={avancada}
                />
              </Col>
            </Row>
            {infoJson.Pesquisa.rapida.tipo === 4 && !avancada && (
              <>
                <Spacer clasName="d-block" height="20px" />
              </>
            )}

            {!avancada && state.allLista.length !== 0 && (
              <div
                onClick={() => {
                  setAvancada(true);
                }}
                className={`${css.btn_avancada}  btn_avancada`}
              >
                <PesquisaAnvanca />
                Pesquisa Detalhada
              </div>
            )}
          </Container>

          {avancada && (
            <>
              <Spacer clasName="d-block" height="30px" />
              <Spacer clasName=" d-lg-none" height="30px" />
            </>
          )}
        </section>
      )}
    </>
  );
}
