import { Container } from "react-bootstrap";
import SectionZone from "../SectionZone";
import CarrouselVendidos from "./CarrouselVendidos";

export default function Vendidos({ allLista, infoJson }) {
  return (
    <>
      <SectionZone
        Titulo={"Últimas Vendidas"}
        SubTitulo={"os mais recentes"}
        clasName={`section-recentes`}
      >
        <Container fluid="xxxl" className={`position-relative noPadding-xs`}>
          {allLista.length !== 0 && (
            <CarrouselVendidos allLista={allLista} infoJson={infoJson} />
          )}
        </Container>
      </SectionZone>
    </>
  );
}
