import { Suspense, lazy } from "react";
// import AuthContext from "../../hooks/AuthContext";

// const SectionZone = lazy(() => import("../../pages/home/SectionZone"));
// const Stand = lazy(() => import("./pontoVenda/Stand"));
const FooterInfo = lazy(() => import("./FooterInfo"));
// const FooterEmpresa = lazy(() => import("./infoEmpresa/FooterEmpresa"));

export default function Footer({ infoJson, allinfoText }) {
  // const { state } = useContext(AuthContext);
  // const [alltexto, setAlltexto] = useState({
  //   Titulo: "",
  //   SubTitulo: "",
  //   Zona: "",
  //   Url: "",
  // });

  // useEffect(() => {
  //   allinfoText.Localizacao && setAlltexto(allinfoText.Localizacao);
  // }, [allinfoText]);

  return (
    <>
      <Suspense fallback={<></>}>
        {/* <FooterEmpresa
          allinfo={state.infoPrincipal[0]}
          infoJson={infoJson}
          allinfoText={allinfoText}
        /> */}
        <FooterInfo
          allinfo={{ NomeEmpresa: "Carmine", Nif: "999 999 999" }}
          infoJson={infoJson}
          allinfoText={allinfoText}
        />
      </Suspense>
    </>
  );
}
