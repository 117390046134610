import { ReactComponent as Comercial } from "../../../../../../assets/icons/tipo/comerc.svg";
import { ReactComponent as Berlina } from "../../../../../../assets/icons/tipo/berlina.svg";
import { ReactComponent as Motos } from "../../../../../../assets/icons/tipo/motos.svg";
import { ReactComponent as Classico } from "../../../../../../assets/icons/tipo/class.svg";
import { ReactComponent as Autocaravanas } from "../../../../../../assets/icons/tipo/autocaravanas.svg";
import { ReactComponent as Barcos } from "../../../../../../assets/icons/tipo/tipo_barco.svg";
import { ReactComponent as Tractor } from "../../../../../../assets/icons/tipo/tractor.svg";
import { ReactComponent as MobileHomes } from "../../../../../../assets/icons/tipo/mob.svg";
import AuthContext from "../../../../../hooks/AuthContext";
import { createLinkPesquisa } from "../../../../../helpers/tratarInfoCarros";
import { useContext } from "react";

export const ListPesquisaAvancado = (infoJson) => {
  const { state } = useContext(AuthContext);

  return [
    {
      exite: state.allCarros.length !== 0 ? true : false,
      eventKey: "automoveis",
      navigate: createLinkPesquisa(infoJson, "automoveis", 1),

      svg: <Berlina />,
      label: "Automóveis",
      array: state.allCarros,
      tipo: createLinkPesquisa(infoJson, "automoveis", 2),
      tipoViatura: 1,
    },
    {
      exite: state.allComercias.length !== 0 ? true : false,
      eventKey: "comerciais",
      navigate: createLinkPesquisa(infoJson, "comerciais", 1),

      svg: <Comercial />,
      label: "Comerciais",
      array: state.allComercias,
      tipo: createLinkPesquisa(infoJson, "comerciais", 2),

      tipoViatura: 1,
    },
    {
      exite: state.allClassicos.length !== 0 ? true : false,
      eventKey: "classicos",
      navigate: createLinkPesquisa(infoJson, "classicos", 1),
      svg: <Classico />,
      label: "Clássicos",
      array: state.allClassicos,
      tipo: createLinkPesquisa(infoJson, "classicos", 2),

      tipoViatura: 1,
    },
    {
      exite: state.allAutocaravanas.length !== 0 ? true : false,
      eventKey: "autoCaravanas",
      navigate: createLinkPesquisa(infoJson, "autoCaravanas", 1),
      svg: <Autocaravanas style={{ width: "45px", height: "20px" }} />,
      label: "Autocaravanas",
      array: state.allAutocaravanas,
      tipo: createLinkPesquisa(infoJson, "autoCaravanas", 2),

      tipoViatura: 1,
    },
    {
      exite: state.mobileHomes.length !== 0 ? true : false,
      eventKey: "mobile-home",
      navigate: createLinkPesquisa(infoJson, "mobile-home", 1),
      svg: <MobileHomes />,
      label: "Mobile Home",
      array: state.mobileHomes,
      tipo: createLinkPesquisa(infoJson, "mobile-home", 2),

      tipoViatura: 1,
    },
    {
      exite: state.allTractor.length !== 0 ? true : false,
      eventKey: "tractor",
      navigate: createLinkPesquisa(infoJson, "tractor", 1),
      svg: <Tractor />,
      label: "Tractor",
      array: state.allTractor,
      tipo: createLinkPesquisa(infoJson, "tractor", 2),

      tipoViatura: 1,
    },
    {
      exite: state.allMotas.length !== 0 ? true : false,
      eventKey: "motas",
      navigate: createLinkPesquisa(infoJson, "motas", 1),
      svg: <Motos />,
      label: "Motas",
      array: state.allMotas,
      tipo: createLinkPesquisa(infoJson, "motas", 2),

      tipoViatura: 2,
    },
    {
      exite: state.allBarcos.length !== 0 ? true : false,
      eventKey: "barcos",
      navigate: createLinkPesquisa(infoJson, "barcos", 1),
      svg: <Barcos stroke="none" />,
      label: "Barcos",
      array: state.allBarcos,
      tipo: createLinkPesquisa(infoJson, "barcos", 2),

      tipoViatura: 3,
    },
  ];
};
