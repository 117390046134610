import { useContext, useEffect, useState } from "react";

import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { tratarLink } from "../../../helpers/tratarInfoCarros";
import ImageRenderer from "../../../tools/images/ImageRenderer";
import { yScrollContext } from "../../../tools/restoreScroll/yScroll";
import css from "./recentes.module.css";
import imgNone from "../../../../assets/static_img/ind_media.webp";

export default function CardBullet({ data, tipo }) {
  const yScroll = useContext(yScrollContext);
  const [imagem, setImagem] = useState("");
  const [srcSet, setSrcSet] = useState("");
  useEffect(() => {
    if (data.Ficheiros.length !== 0) {
      const ImagemPrincipal = data.Ficheiros.filter((carro) => {
        return carro.Principal === true;
      });
      setImagem(
        ImagemPrincipal[0] === undefined
          ? {
              Ficheiro: imgNone,
              Indice: 6,
              Principal: true,
              Ordenador: "",
            }
          : ImagemPrincipal[0]
      );
    } else {
      setImagem({
        Ficheiro: imgNone,
        Indice: 6,
        Principal: true,
        Ordenador: "",
      });
    }
  }, [data]);

  useEffect(() => {
    if (imagem.Ficheiro) {
      const imagemSeloGrande = imagem.Ficheiro;
      const imagemSeloMedio = imagem.Ficheiro.replace("-6.", "-4.");
      const imagemSeloPequeno = imagem.Ficheiro.replace("-6.", "-2.");

      const imageVariants = [
        {
          srcSet: imagemSeloPequeno,
          media: "(max-width: 320px)",
        },
        {
          srcSet: imagemSeloMedio,
          media: "(max-width:991px)",
        },
        {
          srcSet: imagemSeloGrande,
        },
      ];

      setSrcSet(imageVariants);
    }
  }, [imagem]);

  const location = tratarLink(
    data.Marca.Nome,
    data.Viatura === "barcos" ? data.Motorizacao : data.Modelo.Nome,
    data.CodViatura,
    data.Viatura
  );

  const changeClass = (value) => {
    switch (value) {
      case 1:
        return ` ${css.frame} `;

      case 2:
        return ` ${css.frameSquare} `;
      default:
        return ` ${css.frame} `;
    }
  };

  return (
    <Col xs={12}>
      <Link
        aria-label={`${data.Marca.Nome} ${data.Modelo.Nome}`}
        to={location}
        state={{
          backward: {
            yscroll: yScroll,
          },
        }}
      >
        <Col xs={12}>
          {srcSet !== "" && (
            <ImageRenderer
              divName={`frame-recentes nopadding ${changeClass(tipo)}`}
              className={`${css.imagem}`}
              url={srcSet[2].srcSet}
              thumb={srcSet[0].srcSet}
              alt={`${data.Marca.Nome} ${data.Modelo.Nome}`}
              sources={srcSet}
            />
          )}
        </Col>
        <Col className={` nopadding`}>
          <Col xs={12} className={css.marca}>
            {data.Marca.Nome}
          </Col>
          <Col xs={12} className={css.modelo}>
            {data.Viatura === "barcos" ? data.Motorizacao : data.Modelo.Nome}
          </Col>
        </Col>
      </Link>
    </Col>
  );
}
