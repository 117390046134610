import axios from "axios";

const global = require("../config/config");

async function getInfoPontaVenda(pontoVenda) {
  try {
    const accessToken = localStorage.getItem("acess-token");
    var API_URL_INFO = {
      method: "GET",
      url: `${global.ApiLink}/v1/remarketing/empresa/GetContactosClienteById/`,
      params: {
        stand_id: pontoVenda,
      },

      headers: { Authorization: "Bearer " + accessToken.replace(/^"|"$/g, "") },
    };

    const res = await axios.request(API_URL_INFO);
    const info = res.data;
    return info;
  } catch (error) {
    console.log("error", error);
  }
}

async function getInfoEmpresa(infoJson) {
  try {
    const accessToken = localStorage.getItem("acess-token");
    var API_URL_INFO = {
      method: "GET",
      url: `${global.ApiLink}/v1/remarketing/empresa/GetContactosCliente/`,

      headers: { Authorization: "Bearer " + accessToken.replace(/^"|"$/g, "") },
    };

    const res = await axios.request(API_URL_INFO);
    const info = res.data;
    return info;
  } catch (error) {
    console.log("error", error);
  }
}

async function getBanner(codBanner, infoJson) {
  try {
    const accessToken = localStorage.getItem("acess-token");
    const API_URL_BANNERS = {
      method: "GET",
      url: `${global.ApiLink}/v1/remarketing/empresa/GetBanner/`,
      params: {
        zona_id: codBanner,
        numero: infoJson.Banner.Top,
      },

      headers: { Authorization: "Bearer " + accessToken.replace(/^"|"$/g, "") },
    };

    const res = await axios.request(API_URL_BANNERS);

    if (res.data.length > 0) {
      const banners = res.data;
      return banners;
    }
    return [];
  } catch (error) {
    console.log("error", error);
    return [];
  }
}

async function getServicoOficina(infoJson) {
  try {
    const accessToken = localStorage.getItem("acess-token");
    const API_URL_SERVICOS_OFICINA = {
      method: "GET",
      url: `${global.ApiLink}/v1/remarketing/empresa/GetServicoOficina/`,

      headers: { Authorization: "Bearer " + accessToken.replace(/^"|"$/g, "") },
    };

    const res = await axios.request(API_URL_SERVICOS_OFICINA);
    const servico = res.data;

    return servico;
  } catch (error) {
    console.log("error", error);
  }
}

async function getHorarios(CodPontoVenda, Concelho, infoJson) {
  try {
    const accessToken = localStorage.getItem("acess-token");
    const API_URl_HORARIOS = {
      method: "GET",
      url: `${global.ApiLink}/v1/remarketing/empresa/GetHorario/`,
      params: {
        stand_id: CodPontoVenda,
        country: Concelho,
      },

      headers: { Authorization: "Bearer " + accessToken.replace(/^"|"$/g, "") },
    };

    const res = await axios.request(API_URl_HORARIOS);
    const servico = res.data;

    return servico;
  } catch (error) {
    console.log("error", error);
  }
}

async function getIntervaloPreco(infoJson) {
  try {
    const accessToken = localStorage.getItem("acess-token");
    const API_URl_INTERVALO = {
      method: "GET",
      url: `${global.ApiLink}/v1/remarketing/empresa/GetIntervaloPreco/`,

      headers: { Authorization: "Bearer " + accessToken.replace(/^"|"$/g, "") },
    };

    const res = await axios.request(API_URl_INTERVALO);
    const intervalo = res.data;

    return intervalo;
  } catch (error) {
    console.log("error", error);
  }
}
async function getIntervaloMensalidade(infoJson) {
  try {
    const accessToken = localStorage.getItem("acess-token");
    const API_URl_INTERVALO = {
      method: "GET",
      url: `${global.ApiLink}/v1/remarketing/empresa/GetIntervaloMensalidade/`,

      headers: { Authorization: "Bearer " + accessToken.replace(/^"|"$/g, "") },
    };

    const res = await axios.request(API_URl_INTERVALO);
    const intervalo = res.data;

    return intervalo;
  } catch (error) {
    console.log("error", error);
  }
}

async function getPrivacidade(infoJson) {
  try {
    const accessToken = localStorage.getItem("acess-token");
    const API_URl_INTERVALO = {
      method: "GET",
      url: `${global.ApiLink}/v1/remarketing/empresa/privacidade/`,

      headers: { Authorization: "Bearer " + accessToken.replace(/^"|"$/g, "") },
    };

    const res = await axios.request(API_URl_INTERVALO);
    const intervalo = res.data;

    return intervalo;
  } catch (error) {
    console.log("error", error);
  }
}
async function getRegras(infoJson) {
  try {
    const accessToken = localStorage.getItem("acess-token");
    const API_URl_INTERVALO = {
      method: "GET",
      url: `${global.ApiLink}/v1/remarketing/empresa/regras-remarketing/`,

      headers: { Authorization: "Bearer " + accessToken.replace(/^"|"$/g, "") },
    };

    const res = await axios.request(API_URl_INTERVALO);
    const intervalo = res.data;

    return intervalo;
  } catch (error) {
    console.log("error", error);
  }
}
export {
  getInfoEmpresa,
  getBanner,
  getServicoOficina,
  getHorarios,
  getIntervaloPreco,
  getIntervaloMensalidade,
  getPrivacidade,
  getInfoPontaVenda,
  getRegras,
};
