import { useEffect, useState } from "react";
import ReactGA from "react-ga4";

import ReactPixel from "react-facebook-pixel";
import useScriptWidgetsWhats from "./useScriptWidgetsWhats";
import useOneSignal from "./useOneSignal";

export const initGA = (trackingId) => {
  const arr = trackingId.map((item) => {
    return {
      trackingId: item,
    };
  });
  ReactGA.initialize(arr);
};

export default function WidgetProvider({ infoJson, children }) {
  const [initGa, setInitGa] = useState(true);
  const [initFb, setInitFb] = useState(true);

  useEffect(() => {
    if (initGa) {
      initGA(infoJson?.Stand?.GoogleAnalytics?.measurementIds);
      setInitGa(false);
    }
    // Initialize google analytics
  }, [infoJson?.Stand?.GoogleAnalytics?.measurementIds, initGa]);

  useScriptWidgetsWhats(infoJson);
  useOneSignal(infoJson);

  useEffect(() => {
    if (infoJson?.Stand?.PluginFacebok?.existe && initFb) {
      ReactPixel.init(
        infoJson?.Stand?.PluginFacebok?.pixelId,
        {},
        { debug: true, autoConfig: false }
      );
      ReactPixel.pageView();
      ReactPixel.fbq("track", "PageView");
      setInitFb(false);
    }
  }, [infoJson?.Stand?.PluginFacebok, initFb]);

  return <>{children}</>;
}
