import { Suspense, lazy } from "react";
import TopNav from "./header/TopNav";
import Footer from "./footer/Footer";

import useMediaQuery from "../../components/hooks/useMediaQuery ";
import { Outlet, useLocation } from "react-router-dom";

const Dot = lazy(() => import("../../components/tools/dot/Dot"));
const CarComparison = lazy(() =>
  import("../../components/pages/comparador/CarComparison")
);

export default function Layout({
  infoJson,
  allinfoText,
  selectedCarsComparar,
  removeFromCompareAll,
  removeFromCompare,
  setTextoModal,
  textoModal,
  setShowModalComparison,
  showModalComparison,
}) {
  const isDesktop = useMediaQuery("(min-width: 992px)");
  const location = useLocation();

  return (
    <>
      {!(infoJson.Layout?.LandingPage?.existe && location.pathname === "/") &&
        !(
          infoJson.Layout?.LandingPage?.existe &&
          infoJson.Layout?.LandingPage?.novos?.existe &&
          location.pathname === infoJson.Layout?.LandingPage?.novos?.url
        ) && (
          <>
            <TopNav
              path={window.location.pathname}
              infoJson={infoJson}
              allinfoText={allinfoText}
            />
          </>
        )}

      <Outlet />
      <Footer infoJson={infoJson} allinfoText={allinfoText} />

      {infoJson.Layout.Carros.Comparador && selectedCarsComparar.length > 0 && (
        <Suspense fallback={<></>}>
          <CarComparison
            infoJson={infoJson}
            selectedItems={selectedCarsComparar}
            removeFromCompare={removeFromCompare}
            show={showModalComparison}
            setShow={setShowModalComparison}
            textoModal={textoModal}
            setTextoModal={setTextoModal}
            removeFromCompareAll={removeFromCompareAll}
          />
        </Suspense>
      )}
      {isDesktop && infoJson.Layout.ContactarDot && (
        <Suspense fallback={<></>}>
          <Dot selectedItems={selectedCarsComparar} infoJson={infoJson} />
        </Suspense>
      )}
    </>
  );
}
