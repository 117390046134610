import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function Fim() {
  return (
    <section>
      <Container>
        <Row
          className="justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <Col>
            <h1 className="h1">Fim de Leilão</h1>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
