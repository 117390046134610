import  { useState } from "react";

import "./imageRenderer.scss";
import { useInView } from "react-intersection-observer";

const ImageRenderer = ({ sources, url, thumb, divName, children, ...rest }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });

  const handleOnLoad = () => {
    setIsLoaded(true);
  };

  const renderSources = () => {
    if (sources == null) {
      return null;
    }

    const mappedSources = sources.map((source, index) => {
      if (source.srcSet == null) {
        return null;
      }

      return (
        <source
          key={`sources-${index}`}
          data-testid={`sources-${index}`}
          srcSet={source.srcSet}
          media={source.media}
          type={source.type}
        />
      );
    });

    return mappedSources;
  };

  const renderImage = (props, isLoaded, skipSizes) => {
    const {
      alt = "",
      src = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==",
      sizes,
      ...rest
    } = props;

    // Adds sizes props if sources isn't defined
    const sizesProp = skipSizes ? null : { sizes };

    return (
      <>
        <img
          alt={alt}
          className={`image ${isLoaded ? "isLoaded" : ""}`}
          onLoad={handleOnLoad}
          srcSet={src}
          {...sizesProp}
          {...rest}
        />
      </>
    );
  };

  const { alt } = rest;

  return (
    <div className={`image-container ${divName ? divName : ""}`} ref={ref}>
      {inView && (
        <>
          {thumb && (
            <img
              alt={alt}
              className={`image thumb ${isLoaded ? "isLoaded" : ""}`}
              src={thumb}
            />
          )}

          {sources ? (
            <picture>
              {renderSources()}
              {renderImage(rest, true, isLoaded)}
            </picture>
          ) : (
            <img
              alt={alt}
              className={`image ${isLoaded ? "isLoaded" : ""}`}
              onLoad={handleOnLoad}
              src={url}
            />
          )}
        </>
      )}

      {children && <>{children}</>}
    </div>
  );
};

export default ImageRenderer;
