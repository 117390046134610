import { useState, useEffect, Suspense, lazy } from "react";

import { Container, Navbar } from "react-bootstrap";
import css from "./header.module.css";

import { Link } from "react-router-dom";
import NavBarCollapse from "./menuDesktop/NavBarCollapse";

import useMediaQuery from "../../hooks/useMediaQuery ";

import { useLocation } from "react-router";

import { ApiLinkImagens } from "../../../config/config";
import { IfIsTransparent } from "../../helpers/tratarInfoCarros";

const MenuBottom = lazy(() => import("./menuBottom/MenuBottom"));

export default function TopNav({ infoJson, allinfoText }) {
  const location = useLocation();

  const [disabled, setDisabled] = useState(false);
  const ismobile = useMediaQuery("(max-width: 991px)");
  const [noBlur, setNoBlur] = useState(
    `${infoJson.Layout.Transparente.existe ? css.bgNoBlur : null}`
  );
  const [modalFaleConnosco, setModalFaleConnosco] = useState(false);

  const [isScroll, setIsScroll] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [addClass, setAddClass] = useState(
    IfIsTransparent(
      location.pathname,
      infoJson.Layout.Transparente.existe,
      infoJson.Layout?.LandingPage?.existe
    )
      ? "navbar-scroll-custom-visivel"
      : ""
  );
  const [addClassIn, setAddClassIn] = useState(
    IfIsTransparent(
      location.pathname,
      infoJson.Layout.Transparente.existe,
      infoJson.Layout?.LandingPage?.existe
    )
      ? ""
      : css.logoIn
  );
  const [addClassOut, setAddClassOut] = useState(
    IfIsTransparent(
      location.pathname,
      infoJson.Layout.Transparente.existe,
      infoJson.Layout?.LandingPage?.existe
    )
      ? css.logoOut
      : ""
  );

  useEffect(() => {
    IfIsTransparent(
      location.pathname,
      infoJson.Layout.Transparente.existe,
      infoJson.Layout?.LandingPage?.existe
    )
      ? setAddClassOut(css.logoOut)
      : setAddClassOut("");
    IfIsTransparent(
      location.pathname,
      infoJson.Layout.Transparente.existe,
      infoJson.Layout?.LandingPage?.existe
    )
      ? setAddClassIn("")
      : setAddClassIn(css.logoIn);
    IfIsTransparent(
      location.pathname,
      infoJson.Layout.Transparente.existe,
      infoJson.Layout?.LandingPage?.existe
    )
      ? setAddClass("navbar-scroll-custom-visivel")
      : setAddClass("");
  }, [
    location.pathname,
    infoJson.Layout.Transparente.existe,
    infoJson.Layout?.LandingPage?.existe,
  ]);

  useEffect(() => {
    //bloqueia o body
    //para não haver scroll na pagina
    modalFaleConnosco
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "visible");
  }, [modalFaleConnosco]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > lastScrollTop && offset > 20) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
    if (
      IfIsTransparent(
        location.pathname,
        infoJson.Layout.Transparente.existe,
        infoJson.Layout?.LandingPage?.existe
      )
    ) {
      if (offset > 100) {
        setNoBlur("");
        setAddClass("");
        setAddClassIn(css.logoIn);
        setAddClassOut("");
      } else {
        setAddClass("navbar-scroll-custom-visivel");
        setAddClassIn("");
        setNoBlur(`${css.bgNoBlur}`);
        setAddClassOut(css.logoOut);
      }
    }

    setLastScrollTop(offset);
  };

  return (
    <>
      <Navbar
        className={
          isScroll
            ? ` ${css.cor_fundo_claro} corFundoClaro ${css.navbarScrollCustom} ${addClass} ${noBlur}`
            : ` ${css.cor_fundo_claro} corFundoClaro ${addClass} ${noBlur}`
        }
        fixed="top"
        expand="lg"
      >
        <Container fluid="xxxl">
          {IfIsTransparent(
            location.pathname,
            infoJson.Layout.Transparente.existe,
            infoJson.Layout?.LandingPage?.existe
          ) && !infoJson.Layout.Transparente.naoAparecer ? (
            <Navbar.Brand
              as={Link}
              to={`${infoJson.Layout?.LandingPage?.existe ? "/home/" : "/"}`}
              className={` ${addClassIn} logoTranparent ${
                infoJson.Layout.Transparente.Logo.desktop.tipo === 1
                  ? "left"
                  : infoJson.Layout.Transparente.Logo.desktop.tipo === 2 &&
                    "center"
              } ${
                infoJson.Layout.Transparente.Logo.mobile.tipo === 1
                  ? "mobile-left"
                  : infoJson.Layout.Transparente.Logo.mobile.tipo === 2 &&
                    "mobile-center"
              }`}
            >
              <img
                src={`${ApiLinkImagens}/${
                  infoJson?.Layout?.Transparente?.Logo?.url
                    ? infoJson?.Layout?.Transparente?.Logo?.url
                    : "logo"
                }`}
                className={` ${css.navbarBrandAlt}`}
                alt={infoJson.Stand.Nome}
              />
            </Navbar.Brand>
          ) : (
            <></>
          )}

          <Navbar.Brand
            as={Link}
            to={`${infoJson.Layout?.LandingPage?.existe ? "/home/" : "/"}`}
            className={` m-auto ${addClassOut} ${
              infoJson?.Layout?.LogoBarra?.tipo === 2 ? "" : css.navbarBrand
            }`}
          >
            <img
              src={`${ApiLinkImagens}/${
                infoJson?.Layout?.LogoBarra?.url
                  ? infoJson?.Layout?.LogoBarra?.url
                  : "logo"
              }`}
              className={`${css.logo_topo2} logoTopo ${css.navbarBrandAlt}`}
              alt={infoJson.Stand.Nome}
            />
          </Navbar.Brand>

          <NavBarCollapse infoJson={infoJson} allinfoText={allinfoText} />
        </Container>
      </Navbar>

      {ismobile && (
        <>
          <Suspense fallback={<></>}>
            <MenuBottom
              infoJson={infoJson}
              isScroll={isScroll}
              addClass={addClass}
              setModalFaleConnosco={setModalFaleConnosco}
              allinfoText={allinfoText}
              modalFaleConnosco={modalFaleConnosco}
              disabled={disabled}
              setDisabled={setDisabled}
            />
          </Suspense>
        </>
      )}
    </>
  );
}
