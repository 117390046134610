import { Select } from "react-functional-select";
import { useState, useEffect, useCallback, useRef } from "react";
import { ReactComponent as Arrow } from "../../../../../assets/icons/pesquisa/select-seta.svg";
import { ReactComponent as Clear } from "../../../../../assets/icons/pesquisa/fechar-select.svg";
import { ButtonMuiltiSelect } from "../../../../helpers/tratarInfo";
import css from "../pesquisa.module.css";

export default function Marca({ Array, onChange, UpdateSelectRef, infoJson }) {
  const selectRef = useRef();
  const [option, setOption] = useState([]);

  const getOptionValue = useCallback((option) => option.Marca.Id, []);
  const getOptionLabel = useCallback((option) => option.Marca.Nome, []);

  useEffect(() => {
    const Verifica = () => {
      const allListaPorVender = Array.filter((carro) => {
        return carro.Vendido === false;
      });

      const item = allListaPorVender
        .map(({ Marca }) => {
          return {
            Marca,
          };
        })
        .sort(function (a, b) {
          if (a.Marca.Nome < b.Marca.Nome) {
            return -1;
          }
          if (a.Marca.Nome > b.Marca.Nome) {
            return 1;
          }
          return 0;
        })
        .reduce((accum, carro) => {
          const accumulator = [...accum];
          if (!accumulator.some((item) => item.Marca.Id === carro.Marca.Id)) {
            accumulator.push(carro);
          }
          return accumulator;
        }, []);

      setOption(item);
      UpdateSelectRef && UpdateSelectRef(selectRef);
    };

    Verifica();
  }, [Array, UpdateSelectRef]);

  const onOptionChange = (option) => {
    const item = option.map((i) => {
      return i.Marca.Id;
    });
    const selectedInt = item.map((i) => Number(i));
    const allListaPorVender = Array.filter((carro) => {
      return carro.Vendido === false;
    });

    const result = allListaPorVender
      .sort(function (a, b) {
        if (a.Modelo.Nome < b.Modelo.Nome) {
          return -1;
        }
        if (a.Modelo.Nome > b.Modelo.Nome) {
          return 1;
        }
        return 0;
      })
      .filter((carro) => {
        return selectedInt.includes(carro.Marca.Id);
      })
      .map(({ Modelo }) => {
        return {
          Modelo,
        };
      })
      .reduce((accum, carro) => {
        const accumulator = [...accum];
        if (!accumulator.some((item) => item.Modelo.Id === carro.Modelo.Id)) {
          accumulator.push(carro);
        }
        return accumulator;
      }, []);

    onChange(selectedInt, result);
  };

  const renderMultiOptions = useCallback(
    ({ selected }) => (
      <div>
        {selected.length > 0 &&
          `${selected.length} ${
            selected.length === 1 ? "selecionado" : "selecionados"
          }`}
      </div>
    ),
    []
  );
  const clear = (
    <span>
      <Clear fill={infoJson.Cores.SVG} width="10px" />
    </span>
  );
  const arrow = (
    <span>
      <Arrow fill={infoJson.Cores.SVG} className={css.arrow} />
    </span>
  );

  return (
    <>
      <Select
        ref={selectRef}
        isMulti
        isClearable
        isSearchable={false}
        options={option}
        placeholder="Selecionar"
        onOptionChange={onOptionChange}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        openMenuOnClick={true}
        blurInputOnSelect={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        renderMultiOptions={renderMultiOptions}
        clearIcon={clear}
        caretIcon={arrow}
        themeConfig={ButtonMuiltiSelect(infoJson)}
      />
    </>
  );
}
