import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function Token() {
  return (
    <section>
      <Container>
        <Row
          className="justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <Col>
            <h1 className="h1">Acesso Restrito</h1>
            <p className="lead">
              Esta é uma plataforma exclusiva para profissionais do sector
              Automóvel que procuram comprar os melhores carros do mercado ao
              melhor preço. O conteúdo está disponível apenas para utilizadores
              registados. Por favor, registe-se no{" "}
              <a
                className="link-danger"
                href="https://auto.easydata.pt/registar_easydata"
              >
                Easydata
              </a>{" "}
              ou faça login na sua conta{" "}
              <a className="link-danger" href="https://auto.easydata.pt/">
                Easydata
              </a>{" "}
              ou{" "}
              <a className="link-danger" href="https://www.easymanager.pt/">
                Easymanager
              </a>{" "}
              para aceder.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
