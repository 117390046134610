import  { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Tab, TabContent, Tabs } from "react-bootstrap";
import FormPesquisaAvancada from "./FormPesquisaAvancada";
import css from "../pesquisa.module.css";

import { ReactComponent as Seta } from "../../../../../assets/icons/pesquisa/seta_pesquisa.svg";
import useMediaQuery from "../../../../hooks/useMediaQuery ";
import { ListPesquisaAvancado } from "./hooks/listPesquisa";
import AuthContext from "../../../../hooks/AuthContext";

export default function PesquisaAvancada({
  setFilteredCarros,
  infoJson,
  setAvancada,
  avancada,
}) {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [key, setKey] = useState("");
  const { state } = useContext(AuthContext);

  let { viatura } = useParams();

  useEffect(() => {
    if (viatura) {
      switch (viatura) {
        case "viaturas":
        case "automoveis":
          setKey("automoveis");
          break;
        case "comerciais":
          setKey("comerciais");
          break;
        case "classicos":
          setKey("classicos");
          break;
        case "motas":
          setKey("motas");
          break;
        case "barcos":
          setKey("barcos");
          break;
        default:
          setKey("automoveis");
          break;
      }
    } else {
      if (state.allCarros.length !== 0) {
        setKey("automoveis");
      } else {
        if (state.allComercias.length !== 0) {
          setKey("comerciais");
        } else {
          if (state.allClassicos.length !== 0) {
            setKey("classicos");
          } else {
            if (state.allAutocaravanas.length !== 0) {
              setKey("autoCaravanas");
            } else {
              if (state.mobileHomes.length !== 0) {
                setKey("mobile-home");
              } else {
                if (state.allTractor.length !== 0) {
                  setKey("tractor");
                } else {
                  if (state.allMotas.length !== 0) {
                    setKey("motas");
                  } else {
                    if (state.allBarcos.length !== 0) {
                      setKey("barcos");
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }, [
    viatura,
    state.allCarros,
    state.allComercias,
    state.allClassicos,
    state.allAutocaravanas,
    state.mobileHomes,
    state.allTractor,
    state.allMotas,
    state.allBarcos,
  ]);

  return (
    <>
      <div className={` ${!avancada ? "d-none" : "d-block"}`}>
        {isMobile && (
          <Col xs={12}>
            <div
              onClick={() => {
                setAvancada(false);
              }}
              style={{ marginTop: 0 }}
              className={`${css.textoLimpar} textoLimpar `}
            >
              <Seta style={{ marginBottom: 0, marginRight: "5px" }} /> pesquisa
              Rapida
            </div>
          </Col>
        )}

        <Tabs
          defaultActiveKey="automoveis"
          className={`mb-3 ${css.carroucel}`}
          activeKey={key}
          onSelect={(k) => setKey(k)}
          mountOnEnter={true}
        >
          {ListPesquisaAvancado(infoJson).map((item, index) => {
            return (
              item.exite && (
                <Tab
                  key={index}
                  eventKey={item.eventKey}
                  title={
                    <>
                      <div
                        onClick={() =>
                          navigate(item.navigate, {
                            replace: true,
                            state: { pesquisaAvancada: true },
                          })
                        }
                        className={`${css.tab} pesquisa-tab`}
                      >
                        {item.svg}
                        <div>{item.label}</div>
                        <div></div>
                      </div>
                    </>
                  }
                >
                  <TabContent>
                    <FormPesquisaAvancada
                      ArrayCarros={item.array}
                      setFilteredCarros={setFilteredCarros}
                      url={
                        viatura
                          ? viatura
                          : `${
                              infoJson.Layout?.LandingPage?.existe
                                ? "/home/"
                                : "/"
                            }`
                      }
                      tipo={item.tipo}
                      infoJson={infoJson}
                      tipoViatura={item.tipoViatura}
                      allLista={state.allLista}
                    />
                  </TabContent>
                </Tab>
              )
            );
          })}

          {!isMobile && (
            <Tab
              tabClassName="ola"
              title={
                <>
                  <div
                    onClick={() => {
                      setAvancada(false);
                    }}
                    className={`pesquisa-tab ${css.tab} ${
                      !avancada ? "d-none" : "d-flex"
                    }`}
                    style={{ flexDirection: "initial" }}
                  >
                    <Seta
                      style={{
                        marginBottom: 0,
                        marginRight: "5px",
                        height: "25px",
                      }}
                    />
                    Pesquisa Rápida
                  </div>
                </>
              }
            ></Tab>
          )}
        </Tabs>
      </div>
    </>
  );
}
