import  { useEffect, useState } from "react";
import { Range, getTrackBackground } from "react-range";
import css from "./slider.module.css";
import { tratarPreco } from "../../../../helpers/tratarInfoCarros";
import { ReactComponent as SetaDir } from "../../../../../assets/icons/arrow/seta_dir.svg";
import { ReactComponent as SetaEsq } from "../../../../../assets/icons/arrow/seta_esq.svg";
import useMediaQuery from "../../../../hooks/useMediaQuery ";
const STEP = 1;

export default function PrecoSlider({
  ArrayCarros,
  UpdateInicial,
  UpdateFinal,
  PesquisaPrecoInicial,
  PesquisaPrecoFinal,
  infoJson,
}) {
  const ismobile = useMediaQuery("(max-width: 767px)");
  const [values, setValues] = useState([0, 999999]);
  const [MIN, setMIN] = useState(0);
  const [MAX, setMAX] = useState(999999);

  useEffect(() => {
    const min = Math.min.apply(
      Math,
      ArrayCarros.filter((a) => {
        return a.Vendido === false;
      })
        .filter((a) => {
          return a.Preco > 0 ? true : false;
        })
        .map(function (o) {
          return o.Preco;
        })
    );

    setMIN(min);

    const max = Math.max.apply(
      Math,
      ArrayCarros.filter((a) => {
        return a.Vendido === false;
      })
        .filter((a) => {
          return a.Preco > 0 ? true : false;
        })
        .map(function (o) {
          return o.Preco;
        })
    );

    setValues([
      PesquisaPrecoInicial !== "" ? PesquisaPrecoInicial : min,
      PesquisaPrecoFinal !== "" ? PesquisaPrecoFinal : max,
    ]);

    setMAX(max);
  }, [ArrayCarros, PesquisaPrecoInicial, PesquisaPrecoFinal]);

  const handlerValue = (values) => {
    setValues(values);
    UpdateInicial(values[0]);
    UpdateFinal(values[1]);
  };

  return (
    <>
      {ismobile && (
        <output className={`${css.values}`}>
          de {tratarPreco(values[0])} até {tratarPreco(values[1])}
        </output>
      )}
      <Range
        values={values}
        step={STEP}
        min={MIN}
        max={MAX}
        onChange={(values) => handlerValue(values)}
        renderTrack={({ props, children }) => (
          <div
            //   onMouseDown={props.onMouseDown}
            // onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
            }}
            className={css.rectangle}
          >
            <div
              ref={props.ref}
              style={{
                height: "2px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values,
                  colors: ["#ccc", `${infoJson.Cores.Principal}`, "#ccc"],
                  min: MIN,
                  max: MAX,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ index, props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
            }}
            className={`${css.box} box-slider`}
          >
            {ismobile ? (
              <div className={`${css.arrow}`}>
                <SetaEsq stroke={isDragged ? "#b9b9b9" : "#fff"} />
                <SetaDir stroke={isDragged ? "#b9b9b9" : "#fff"} />
              </div>
            ) : (
              <div className={`${css.number}`}>
                {tratarPreco(values[index])}
              </div>
            )}
          </div>
        )}
      />
    </>
  );
}
